import React, { createContext, useCallback, useContext } from 'react';
import { ThemeProvider } from 'styled-components';

import usePersistState from '../utils/usePersistState';
import light from '../styles/theme/light';
import dark from '../styles/theme/dark'; 

import { ChakraProvider } from '@chakra-ui/react';
import { theme } from '../styles/theme';
import { ConfigProvider, theme as themeAntd } from 'antd';
import 'dayjs/locale/pt-br';
import locale from 'antd/locale/pt_BR';

const ThemeContext = createContext();
const { defaultAlgorithm, darkAlgorithm } = themeAntd

const MyThemeProvider = ({ children }) => {
    
    const [themeStyled, setTheme] = usePersistState('@amoraid:theme', dark);
    
    const toggleTheme = useCallback(() => {
        setTheme(themeStyled.title === 'light' ? dark : light);
    }, [themeStyled, setTheme]);

    return (
        <ThemeProvider theme={themeStyled}>
            <ThemeContext.Provider value={{ toggleTheme }}>
                    <ChakraProvider theme={theme}>
                        <ConfigProvider 
                            locale={locale}
                            theme={{
                                algorithm: themeStyled.title === 'ligth' ? defaultAlgorithm : darkAlgorithm
                            }}
                        >
                            {children}
                        </ConfigProvider>
                    </ChakraProvider>
            </ThemeContext.Provider>
        </ThemeProvider>
    )
};

export function useTheme(){
    const context = useContext(ThemeContext);
    return context;
}

export default MyThemeProvider;