import React from 'react';

import ThemeContext from './ToggleThemeContext';
import { AuthProvider } from './Auth';
import { ToastProvider } from './ToastContext';
import { AlertProvider } from './Alert';
import { QueryClient, QueryClientProvider } from 'react-query';

export const queryClient = new QueryClient();

const AppProvider = ({children}) => {
    return (
        <ThemeContext>
                <AuthProvider>
                    <ToastProvider>
                        <AlertProvider>
                            <QueryClientProvider client={queryClient}>
                                {children}
                            </QueryClientProvider>
                        </AlertProvider>
                    </ToastProvider>
                </AuthProvider> 
        </ThemeContext>
    );
};

export default AppProvider;