import Datepicker, { registerLocale } from 'react-datepicker'
import pt from 'date-fns/locale/pt-BR'
import { Container } from './styles'

import 'react-datepicker/dist/react-datepicker-cssmodules.css';

registerLocale('pt-br', pt)


export function MovementPeriodDates({ register }) {
    return (
        <Container>
            <Datepicker {...register('startDate')} locale="pt-br" />
            <Datepicker {...register('endDate')} locale="pt-br"  />
        </Container>
    )
}