import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Dashboard from '../pages/Dashboard';
import EconomicAnalysis from '../pages/EconomicAnalysis';
import InstalmentsFinance from '../pages/InstalmentsFinance';
import Layout from '../pages/TestsLayout';
import Settings from '../pages/Settings';
import BankMovement from '../pages/BankMovement';
import MontlyBalanceSheet from '../pages/MontlyBalanceSheet';
// import PriceQuote from '../pages/PriceQuote';

const Routes = () => {
    return (
        <Switch>
            <Route path="/" exact component={SignIn} />
            <Route path="/signup" component={SignUp} />

            <Route path="/prototipo/balancete-mensal" component={MontlyBalanceSheet} />

            <Route path="/layout" isPrivate component={Layout} />
            <Route path="/dashboard/" exact isPrivate component={Dashboard} />
            <Route path="/dashboard/installmentsunits" isPrivate component={InstalmentsFinance} />
            <Route path="/dashboard/economicanalysis" isPrivate component={EconomicAnalysis} />
            <Route path="/dashboard/movimentos-caixas-e-bancos" isPrivate component={BankMovement} />
            {/* <Route path="/dashboard/pricequote" isPrivate component={PriceQuote} /> */}
            <Route path="/dashboard/settings" isPrivate component={Settings} />
        </Switch>
    );
}

export default Routes;