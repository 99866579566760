import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query';
import Select from 'react-select'
import { 
    Heading,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
 } from '@chakra-ui/react';
//  import { Chart } from 'react-charts';

 import Button from '../../components/Button';
import CardContainer from '../../components/CardContainer';
import Loading from '../../components/Loading';
import api from '../../utils/api'

import { 
     ActionsForTable, 
     ContainerFilterTopHeader,
    //  GridContainer,
     Header, 
 } from './style';
// import MyChart from '../../components/Chart';
// import ReactDatePicker from 'react-datepicker';

// import "react-datepicker/dist/react-datepicker.css";
import { MovementPeriodDates } from './components/MovementPeriodDates';
function BankMovement()
{
    const [isRequesting, setIsRequesting] = useState(false)
    const { register, handleSubmit, control } = useForm()
    const { data: enterprisesResponse, isLoading } = useQuery('enterprises', async () => {
        const response = await api.get('/enterprises?limit=200');
               
        return response.data.enterprises
    })
    // const { data } = useQuery('movement', async () => {
    //     const response = await api.get('/bank-movement', {
    //         params: {
    //             startDate: '2023-06-01',
    //             endDate: '2023-07-01',
    //             costCenters: '2200',
    //         }
    //     })
    //     return response.data.result
    // })
    const [data, setData] = useState([])
    const enterprises = enterprisesResponse ? enterprisesResponse.map(enterprise => ({
        value: enterprise.id,
        label: `${enterprise.id} - ${enterprise.name}`,
    })) : [];

    async function onSubmit(data) {
        // console.log(data)
        try {
            setIsRequesting(true)
            const response = await api.get('/bank-movement', {
                params: {
                    startDate: '2023-06-01',
                    endDate: '2023-07-01',
                    costCenters: data.costCenters.value,
                }
            })
    
            // console.log('response',response.data)
            
            if(response.data && response.data.movements.result) {
                const movementFormatted = response.data.movements.result.map(movement => ({
                    ...movement,
                    bankMovementReconcile: movement.bankMovementReconcile === 'S' ? 'Sim' : 'Não',
                    bankMovementAmount: `${movement.bankMovementOperationType === 'S' ? '-' : ''}${new Intl.NumberFormat('pt-br').format(movement.bankMovementAmount)}`,
                    total: new Intl.NumberFormat('pt-br', { currency: 'BRL', style: 'currency'}).format(movement.total),
                }))
                setData(movementFormatted)
            }
        } catch (error) {
            
        } finally {
            setIsRequesting(false)
        }
    }

    // const costCenters = [
    //     {
    //       label: 'Series 1',
    //       data: [
    //         20,25,26,33
    //       ],
    //     },
    //     {
    //       label: 'Series 2',
    //       data: [
    //         20,25,26,33
    //       ],
    //     }
    //   ] 

    // console.log(data)
    return (
        <>
        <Loading isVisabled={isRequesting} />
        {/* Content Main */}
        <Header>
            <div className="description">
                <Heading as="h1" size="lg">Movimento de Bancos</Heading>
            </div>
            <p>Parametros de consulta</p>
            <form onSubmit={handleSubmit(onSubmit)} style={{width: 400}}>
                <Controller 
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (

                        <Select 
                            options={enterprises} 
                            isLoading={isLoading} 
                            classNamePrefix="rc-select" 
                            value={value}
                            onChange={(value) => onChange(value)}
                        />
                    )}
                    name='costCenters'
                />
                <MovementPeriodDates register={register} />
                <Button type="submit" colorStyle="primary">Consultar</Button>
            </form>
        </Header>
        {/* <GridContainer> */}
            <CardContainer>
                <ActionsForTable>
                    <strong>Tabela de Vendas - </strong>
                    
                </ActionsForTable>
                    <ContainerFilterTopHeader>
                    
                        <div className="description">
                        </div>
                    </ContainerFilterTopHeader>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Conta</Th>
                                <Th>Conciliado</Th>
                                <Th>N Doc.</Th>
                                <Th>Centro de custo</Th>
                                <Th>Cliente/Fornecedor</Th>
                                <Th>Débito</Th>
                                <Th>Crédito</Th>
                                <Th>Saldo</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data && data.map(movement => (
                                <Tr key={movement.id}>
                                    <Td>{movement.accountNumber}</Td>
                                    <Td>{movement.bankMovementReconcile}</Td>
                                    <Td>{movement.id}</Td>
                                    <Td>{movement.costCenter}</Td>
                                    <Td>{movement.creditor}</Td>
                                    <Td>{movement.bankMovementOperationType === 'S' ? movement.bankMovementAmount : ''}</Td>
                                    <Td>{movement.bankMovementOperationType === 'E' ? movement.bankMovementAmount : ''}</Td>
                                    <Td>{movement.total}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
            </CardContainer>
            {/* <CardContainer>
                <strong>Gráfico por Centro de custos </strong>
                <MyChart theme="dark" />
            </CardContainer>
        </GridContainer> */}
        </>
    );
}

export default BankMovement;