import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    *{
        margin: 0px;
        padding: 0px;
        outline: 0px;
        box-sizing: border-box;
    } 
    
    body{
        background: ${props => props.theme.colors.background} !important;
        color: ${props => props.theme.colors.text} !important;
    }

    body, input, textarea, label, button{
        font-family: 'Source Sans Pro', sans-serif !important;
    }

    button{
        cursor: pointer;
    }

    h1, h2, h3, h4, h5, h6{
        font-weight: 900;
    }

    /* .ant-select {
        height: 56px;
       
        .ant-select-selector {
            background: ${props => props.theme.colors.superimposedBackground} !important;
            color: ${props => props.theme.colors.label};
            border-color: ${props => props.theme.colors.superimposedBackground} !important;
            height: 100%;
            
        }
        .ant-select-single .ant-select-selector { 
            height: 100%;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector input {
            height: 100%;
            color: ${props => props.theme.colors.text};
            &::placeholder { color: ${props => props.theme.colors.label}; }
        }
    } */
    

    .rc-select__control {
        background: ${props => props.theme.colors.superimposedBackground} !important;
        color: ${props => props.theme.colors.label};
        border-color: ${props => props.theme.colors.superimposedBackground} !important;
        height: 56px;

        .rc-select__input {
            height: 100%;
            color: ${props => props.theme.colors.text} !important;
        }
        
        .rc-select__single-value {
            color: ${props => props.theme.colors.text} !important;
        }
        .rc-select__indicators {
            .rc-select__indicator-separator {
                background-color: ${props => props.theme.colors.label};
            }
        }
    }


    .rc-select__menu {
        
        .rc-select__menu-list { 
            background: ${props => props.theme.colors.superimposedBackground};
            color: ${props => props.theme.colors.text};

            .rc-select__option {
                &.option--is-focused { 
                    background: red;
                }
                &:hover {
                    background: rgba(${props => props.theme.colors.primary}, 0.1);
                }
            }
        }
    }

`;