import { useState, useEffect } from 'react';

function usePersistState(key, initialState)
{
    const [ state, setState ] = useState(() => {
        const storageValue = localStorage.getItem(key);

        if(storageValue){
            return JSON.parse(storageValue);
        }
        
        localStorage.setItem(key, JSON.stringify(initialState));

        return initialState;
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);

    return [ state, setState];
}

export default usePersistState;