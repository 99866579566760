import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Empty, Select, Button, DatePicker } from 'antd'
import { 
    Heading,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Divider,
 } from '@chakra-ui/react';

//  import Button from '../../components/Button';
import CardContainer from '../../components/CardContainer';
import Loading from '../../components/Loading';

import { 
     ActionsForTable, 
     ContainerFilterTopHeader,
     DataEmpty,
     Enterprise,
     Header, 
     HeaderTableCategory
 } from './style';

import Dashboard from './components/Dashboard';
import dataFake from './data.json'
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'

dayjs.locale('pt-br')

function MontlyBalanceSheet()
{
    const [isRequesting, setIsRequesting] = useState(false)
    const [dateSelected, setDateSelected] = useState(dayjs())
    const [category, setCategory] = useState()
    const { handleSubmit, control } = useForm()
    const [data, setData] = useState({
        revenues: [],
        expenses: []
    })
    
    const enterprises = [{
        value: 1,
        label: '1 - SPE PIRATININGA',
    }];

    async function onSubmit(data) {
        setIsRequesting(true)
        await new Promise((resolve) => setTimeout(resolve, 3000))
        setDateSelected(data.period_date)
        setData(dataFake)
        setIsRequesting(false)
        console.log(data)
    }

    const dataFilter = category ? (category === 'revenues' ? {...data, expenses: []} : {...data, revenues: []}) : data  
    console.log(category)
    return (
        <Dashboard>
        <Loading isVisabled={isRequesting} />
        {/* Content Main */}
        <Header>
            <div className="description">
                <Heading as="h1" size="lg">Balancete Mensal</Heading>
                <p>Selecione os parametros de pesquisa ao lado para consultar.</p>
            </div>
            {/* <p>Parametros de consulta</p> */}
            <form onSubmit={handleSubmit(onSubmit)}>
            <Controller 
                    control={control}
                    name='enterprise'
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select 
                            ref={ref} 
                            onChange={(value) => onChange(value)}
                            onBlur={onBlur}
                            value={value}
                            options={enterprises}
                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                            placeholder="Selecionar empreendimento"
                            size='large'
                        />
                    )}
                />
                <Controller 
                    control={control}
                    name='period_date'
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <DatePicker
                            // {...register('period')}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            ref={ref}
                            format="MM/YYYY" 
                            size='large' 
                            style={{ marginLeft: 16, marginRight: 16 }} 
                            picker="month" 
                        />
                    )}
                />
                <Button type="primary" htmlType='submit' size='large' >Consultar</Button>
            </form>
        </Header>
        {/* <GridContainer> */}
            <CardContainer>
                <ActionsForTable>
                    <strong>Resultado - {data.expenses.length > 0 && <Enterprise>SPE PIRATININGA RUA 17 - {dateSelected.format('MMMM [de] YYYY')}</Enterprise>}</strong>
                    
                </ActionsForTable>
                    <ContainerFilterTopHeader>
                        <Select 
                            placeholder="Selecionar categoria" 
                            options={[
                                { label: 'Tudo', value: 'all'},
                                { label: 'Despesas', value: 'expenses'},
                                { label: 'Receitas', value: 'revenues'},
                            ]} 
                            style={{ minWidth: 150 }}
                            onChange={(value) => value === 'all' ? setCategory(undefined) : setCategory(value)}
                            disabled={data.expenses.length === 0}
                        />
                        <Select placeholder="Selecionar plano financeiro" disabled />
                    </ContainerFilterTopHeader>
                    {dataFilter.expenses.length === 0 && dataFilter.revenues.length === 0 && (
                        <DataEmpty>
                            <Empty description="Nenhum dado encontrado ou selecionado" />
                        </DataEmpty>
                    )}
                    {dataFilter.revenues.length > 0 && (
                        <div style={{ marginBottom: 32 }}>
                            <HeaderTableCategory type="revenues">
                                <span>Receitas</span>
                            </HeaderTableCategory>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th borderColor={"#393E43"} fontSize="12px" width="80%">Descrição</Th>
                                        <Th borderColor={"#393E43"} fontSize="12px">Valor</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {data.revenues.map(revenue => {
                                        return (
                                        <>
                                            <Tr fontSize="14px" key={revenue.description}>
                                                <Td borderColor={"#393E43"}><strong>{revenue.code} - {revenue.description}</strong></Td>
                                                <Td borderColor={"#393E43"}>{revenue.amount || '-'}</Td>
                                            </Tr>
                                            {revenue.children && revenue.children.map(revenueChild => (
                                                <Tr fontSize="14px" key={revenueChild.description}>
                                                    <Td borderColor={"#393E43"} paddingLeft={10}>{revenue.code} - {revenueChild.description}</Td>
                                                    <Td borderColor={"#393E43"}>{revenueChild.amount}</Td>
                                                </Tr>
                                            ))}
                                        </>)
                                    })}
                                    <Tr bg={"#212529"} fontSize="14px" >
                                        <Td borderColor={"transparent"}><strong>TOTAL DE RECEITAS</strong></Td>
                                        <Td borderColor={"transparent"} color="#5CDEA3">R$ 80.009,68</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </div>
                    )}
                    <Divider background={"#212529"}/>
                    {dataFilter.expenses.length > 0 && (
                        <div style={{ marginTop: 32 }}>
                            <HeaderTableCategory type="expenses">
                                <span>Despesas</span>
                            </HeaderTableCategory>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th borderColor={"#393E43"} fontSize="12px" width="80%">Descrição</Th>
                                        <Th borderColor={"#393E43"} fontSize="12px">Valor</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {data.expenses.map(expense => {
                                        return (
                                        <>
                                            <Tr fontSize="14px" key={expense.description}>
                                                <Td borderColor={"#393E43"}><strong>{expense.description}</strong></Td>
                                                <Td borderColor={"#393E43"}>{expense.amount || '-'}</Td>
                                            </Tr>
                                            {expense.children && expense.children.map(expenseChild => (
                                                <Tr fontSize="14px" key={expenseChild.description}>
                                                    <Td borderColor={"#393E43"} paddingLeft={10}>{expenseChild.description}</Td>
                                                    <Td borderColor={"#393E43"}>{expenseChild.amount}</Td>
                                                </Tr>
                                            ))}
                                        </>)
                                    })}
                                    <Tr bg={"#212529"} fontSize="14px" >
                                        <Td borderColor={"transparent"}><strong>TOTAL DE RECEITAS</strong></Td>
                                        <Td borderColor={"transparent"} color="#ff6565">R$ 588,50</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </div>
                    )}
            </CardContainer>
            
        </Dashboard>
    );
}

export default MontlyBalanceSheet;