import { jsPDF } from 'jspdf';
import 'jspdf-autotable';   
import logotipoDefault from '../assets/amora.id.png';
import axios from 'axios';
import FormatDataForTable from './formatDataForTable';

const headerDefault = [
    {label: '#QUADRA', value: 'qd', abbr: 'qd'},
    {label: '#LOTE', value: 'lt', abbr: 'lt'},
    {label: '#Área m²', value: 'privateArea', abbr: 'Área m2'},
    {label: '#Á vista', value: 'indexedQuantity', abbr: 'Á vista'},
    {label: '#ENTRADA %', value: 'inputValue', abbr: 'Entrada %'},
    {label: '#FINANCIAMENTO', value: 'amount', abbr: 'F'},
];

const numberOfInstallmentDefault = {value: 180, label: '180x'};

const exportUnitsPDF = async (data, enterprise = null, filename = "amoraid-export", table_header_color = null) => {
    
    /** verifica se passou configurações para exportação
     *  alterando a ordem do cabeçalho até as parcelas &&
     *  se existe configução de até quantas parcelas
     */
    let configHeaderExport = localStorage.getItem('@amoraid:configDocumentExportHeaderInstallments');
    if(configHeaderExport) configHeaderExport = JSON.parse(configHeaderExport);

    let configInstallmentsExport = localStorage.getItem('@amoraid:configDocumentExportNumberInstallments');
    if(configInstallmentsExport) configInstallmentsExport = JSON.parse(configInstallmentsExport);
    
    const instanceFormatDataForTable = new FormatDataForTable();
    const { data: newData, headers } = instanceFormatDataForTable
    .execute(data, configHeaderExport ?? headerDefault, !!configInstallmentsExport ? configInstallmentsExport : numberOfInstallmentDefault);
    
    const { fontSize, perPage, cellWidth } = verifySizes(headers.length);

    const dataFormat = handleDividerDataForPage(newData, perPage);
    const logotipos = await handleGetBrandEnterprise(enterprise.id);
    
    const doc = new jsPDF({
        orientation: "landscape",
        unit: 'px',
        putOnlyUsedFonts: true
    }).setProperties({
        title: !enterprise ? 'Amoraid Export PDF - amoraid.com.br' : `${enterprise.name} - amoraid`,
        author: 'Amoratec - amora.id',
    });

    var dataLength = dataFormat.length - 1;
    dataFormat.forEach((dataCurrent, index) => {
        /** Header */
        doc.setFontSize(12);
        doc.setTextColor('#707070');
        doc.text("Tabela de preços", 32, 30);
        doc.setFontSize(14);
        doc.setTextColor(59, 134, 255);
        doc.text(`${enterprise.id} - ${enterprise.name}`, 32, 46);
        /** break line include \r\n */
        doc.setTextColor('#707070');
        doc.setFontSize(8);
        doc.text(enterprise.adress, 32, 56);

        /** logotipo primary */
        let positionY = doc.internal.pageSize.width;
        doc.addImage(logotipos.primary ? logotipos.primary : logotipoDefault, 
            'PNG', (positionY - 132), 20, 100, 50);
        /** logotipo enterprise */
        if(logotipos.enterprise) doc.addImage(logotipos.enterprise, 'PNG', (positionY - 232), 20, 100, 50);
        
        /** Main */
        let margin;

        if(cellWidth !== 'auto') {
            let wantedTableWidth = cellWidth * headers.length;
            let pageWidth = doc.internal.pageSize.width;
            margin = (pageWidth - wantedTableWidth) / 2;
        }else {
            margin = 32;
        }
        
        doc.autoTable({
            theme: 'striped',
            headStyles: {
                fontSize,
                halign: 'center',
                fillColor: table_header_color ? table_header_color : '#3B86FF',
            },
            bodyStyles: {
                cellWidth,
                fontSize,
                halign: 'center',
            },
            startY: 84,
            margin: {left: margin, right: margin},
            head: [headers],
            body: dataCurrent,
        });
        /** Footer */
        if(index !== dataLength) doc.addPage();
    });
        
    /** Numeric pages */
    var pageCount = doc.getNumberOfPages();
    for (let i = 0; i < pageCount; i++) {
        doc.setPage(i);
        let positionY = doc.internal.pageSize.height - 16;
        let positionX = doc.internal.pageSize.width - 64;
        doc
        .setFontSize(10)
        .text("Página " + doc.getCurrentPageInfo().pageNumber + " / " + pageCount, positionX, positionY);   
        
        /** description footer */
        let positionXDescription = (doc.internal.pageSize.width / 2) - 32;
        let positionYDescription = positionY - 4;
        doc
        .setFontSize(8)
        .text("TABELA DE PREÇOS", positionXDescription, positionYDescription);

        doc.setFontSize(6)
        .text("Esta tabela poderá sofrer alterações sem aviso prévio", 
        positionXDescription - 18, positionYDescription + 6)
        
        doc
        .text("As prestações serão corrigidas mensalmente pelo IGP-M e atualizadas anualmente", 
        positionXDescription - 48, positionYDescription + 12)
    }

    doc.save(filename + '.pdf');
};

const handleDividerDataForPage = (data, perPage) => {
    var newArray = [];
    // var perPage = 23
    // if(lengthHeader) {

    // }
    const state = {
        totalPage:  Math.ceil(data.length / perPage),
        perPage,
        page: 1,
    }
    
    for (let index = 0; index < state.totalPage; index++) {
        let page = index;
        let start = page * state.perPage;
        let end = start + state.perPage;
        const dataSlice = data.slice(start, end);
        newArray[index] = dataSlice;           
    }

    return newArray;
};

const verifySizes = ( lengthHeader ) => {
    if(lengthHeader <= 8) return ({
        fontSize: 8,
        perPage: 22,
        cellWidth: 70,
    });

    return {
        fontSize: 6,
        perPage: 23,
        cellWidth: 'auto',
    };
}
const handleGetBrandEnterprise = async (id) => {
    const user = JSON.parse(localStorage.getItem('@amoraid:user'));
    const url = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('@amoraid:token');
    var urlImage = {
        enterprise: null,
        primary: null,
    };

    const config = {
        headers: { Authorization: `Bearer ${token}`}
    };

    try{
        const brand = await axios.get(`${url}/enterprises/brand/${id}`, config);
        if(brand.data) urlImage.enterprise = `${url}/files/${brand.data.filename}`;
    }catch(err){}

    try {
        const primary = await axios.get(`${url}/users/${user.id}`, config);

        if(primary.data){
            const { data } = primary;
            if(data.file_brand) urlImage.primary = `${url}/files/${primary.data.file_brand}`;
        }
    } catch (error) {}
    

    return urlImage;
};

export default exportUnitsPDF;
